// /* ------------------------------------------------------------------------------
// @name: Title Page
// @description: Title Page
// --------------------------------------------------------------------------------- */

// const TitlePage = (() => {

//   // --- Particle
//   const handleParticle = () => {
//     particleJS.load('#particles-js', 'assets/js/data/particles-js.json', function() {
//       // console.log('callback - particles.js config loaded');
//     });
//   }


  
//   // init
//   const init = () => {
//     handleParticle();
//   }

//   return {
//     init
//   }

// })();

// export default TitlePage;
